import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`An IconButton is just like a regular `}<a parentName="p" {...{
        "href": "/components/button/code"
      }}>{`Button`}</a>{` and
accepts any icons from `}<a parentName="p" {...{
        "href": "/components/foundations/iconography/"
      }}>{`Chameleon Icons`}</a>{`.`}</p>
    <h2 {...{
      "id": "appearances",
      "style": {
        "position": "relative"
      }
    }}>{`Appearances`}</h2>
    <p>{`IconButtons have the same variations for its appearance as the regular buttons,
but with an additional `}<inlineCode parentName="p">{`default`}</inlineCode>{` appearance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton icons=Brand wrap=flex",
        "componentName": "IconButton",
        "icons": "Brand",
        "wrap": "flex"
      }}>{`<IconButton appearance="default" icon={Brand} />
<IconButton appearance="primary" icon={Brand} />
<IconButton appearance="secondary" icon={Brand} />
<IconButton appearance="plain" icon={Brand} />
<IconButton appearance="outline" icon={Brand} />
`}</code></pre>
    <h3 {...{
      "id": "appearance-default",
      "style": {
        "position": "relative"
      }
    }}>{`Appearance default`}</h3>
    <p>{`The IconButton with an appearance of `}<inlineCode parentName="p">{`default`}</inlineCode>{` has a black background with a
`}<strong parentName="p">{`transparancy of 5%`}</strong>{` on hover and has the option to change the icon's color.
It is especially helpful when using it on a colored backtround as in the example
below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton wrap=flex",
        "componentName": "IconButton",
        "wrap": "flex"
      }}>{`<Banner appearance="info" show>
  An IconButton with an appearance of default is used to close this banner.
</Banner>
`}</code></pre>
    <h2 {...{
      "id": "sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Sizes`}</h2>
    <p>{`IconButtons come in two sizes, large (48px x 48px) and small (32px x 32px).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton wrap=flex",
        "componentName": "IconButton",
        "wrap": "flex"
      }}>{`<IconButton size="small" appearance="primary" icon={Brand} />
<IconButton size="large" appearance="primary" icon={Brand} />
`}</code></pre>
    <h2 {...{
      "id": "shapes",
      "style": {
        "position": "relative"
      }
    }}>{`Shapes`}</h2>
    <p>{`The default shape of the IconButton is squared, but can be customized to a
circular shape when needed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=IconButton wrap=flex",
        "componentName": "IconButton",
        "wrap": "flex"
      }}>{`<IconButton size="small" circular={true} appearance="primary" icon={Brand} />
<IconButton size="large" circular={true} appearance="primary" icon={Brand} />
`}</code></pre>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`If you're looking for a regular button with text or text and an icon, use the
`}<a parentName="li" {...{
          "href": "/components/button/code"
        }}>{`Button`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      